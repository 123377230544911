import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCsrfToken } from "./csrfSlice";

import Swal from "sweetalert2";
import axiosInstance from "../../utils/axiosInstance";

export const signupUser = createAsyncThunk(
  "user/signupUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/auth/signup",
        userData
      );
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Sign Up successfull',
      });
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error Signing Up",
        text:errorMessage
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (credentials, { dispatch }) => {
    try {
      const response = await axiosInstance.post('/auth/signin', credentials, { withCredentials: true });
      
      // Fetch CSRF token first
      await dispatch(fetchCsrfToken());
      
      // Then verify session
      await dispatch(verifySession());

      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Logged In successfully',
      });
      
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml: '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error Logging In",
        text:errorMessage
      });
      throw error;
    }
  }
);

export const verifySession = createAsyncThunk(
  "user/verifySession",
  async (_, { rejectWithValue ,getState}) => {
    try {
      const response = await axiosInstance.get('/auth/verify-session', { withCredentials: true });
      return response.data;
    } catch (error) {
      const state =getState();
       if (error.response?.status === 401 && state.isInitialFetch ) {
        Swal.fire({
          iconHtml: '<i class="fas fa-exclamation-circle text-warning font-55 m-b-25"></i>',
          title: "Session Expired",
          text: "Your session has expired. Please log in again.",
        });
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
        }  
        localStorage.removeItem('lastPath');
       }
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get('/auth/logout' ,  { withCredentials: true });
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      }
      localStorage.removeItem('lastPath');
      sessionStorage.clear();
      
      // Clear all cookies
      document.cookie.split(";").forEach(function(c) { 
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
      });

      thunkAPI.dispatch(resetState());

      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Logged out successfully',
      });
   
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error Logging Out",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    user1:null,
    token: null,
    isLoggedIn:false,
    status: "idle",
    error: null,
    isInitialFetch:true
  },
  reducers: {
    resetSignupSuccess: (state) => {
      state.signupSuccess = false;
    },
    resetState: (state) => {
      // Reset the state to initial values
      Object.assign(state, {
        user: null,
        token: null,
        isLoggedIn: false,
        status: "idle",
        error: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = null;  // Don't set the user here, as they need to log in
        state.signupSuccess = true;  // Add this line
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.isLoggedIn=true;
        state.isInitialFetch=false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(verifySession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifySession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.user1 = action.payload;
        state.isLoggedIn = action.payload?.data?.data?.status === "success";
      })
      .addCase(verifySession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "succeeded";
        state.user = null;
        state.token = null;
        state.isLoggedIn=false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetSignupSuccess, resetState  } = userSlice.actions;
export default userSlice.reducer;

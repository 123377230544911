import React from 'react';
import AnimateLogo from './AnimateLogo';

function Loader({ height }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <AnimateLogo/>
      </div> 
   
   
  );
}

export default Loader;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import { fetchPaginatedConnections } from './connectionSlice';
import axiosInstance from '../../utils/axiosInstance';



export const enableConnection = createAsyncThunk(
  'connection/enable',
  async (connectionIDs, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/connections/enable', {
        connection_ids: connectionIDs,
      });
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Connection enabled successfully',
      });
      
      const state = getState();
      const folderID = state?.folders?.selectedFolderId;
      await dispatch(fetchPaginatedConnections({ folder_id: folderID }));
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error enabling connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const disableConnection = createAsyncThunk(
  'connection/disable',
  async (connectionIDs, {dispatch,getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/connections/disable', {
        connection_ids: connectionIDs,
      });
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Connection disabled successfully',
      });
      const state = getState();
      const folderID = state?.folders?.selectedFolderId;
      await dispatch(fetchPaginatedConnections({ folder_id: folderID }));
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error disabling connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const actionSlice = createSlice({
  name: 'action',
  initialState: {
    isLoading: false,
    error: null,
    successMessage: null,
    showMoveConnectionModal:false,
    showDeleteConnectionModal:false,
  },
  reducers: {
    clearMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
    setShowMoveConnectionModal:(state,action)=>{
      state.showMoveConnectionModal=action.payload
    },
    setShowDeleteConnectionModal:(state,action)=>{
      state.showDeleteConnectionModal=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(enableConnection.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(enableConnection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = 'Connection enabled successfully';
      })
      .addCase(enableConnection.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(disableConnection.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(disableConnection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = 'Connection disabled successfully';
      })
      .addCase(disableConnection.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearMessages,setShowMoveConnectionModal, setShowDeleteConnectionModal} = actionSlice.actions;

export default actionSlice.reducer;

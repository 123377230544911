import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../utils/axiosInstance";
import Swal from 'sweetalert2';
import { fetchFolders, updateFolderConnectionCounts } from './folderSlice';
import { fetchStats } from './statsSlice';

const initialState = {
  connections: [],
  status: "idle",
  getAllConnectionStatus: "",
  moveConnections: [],
  error: null,
  isFilterApplied: false,
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 10,
  connectionUpdateData: null,
  paginatedConnectionsStatus: "idle",
  isInitialFetch: true,
  isSelectActionVisible: false,
  checkedItems: [],
  connectionId: null,
  loading:false,
  isRequestCountClicked:false,
  isEventCountClicked:false,
  requestConnectionId:'',
  eventConnectionId:''
};


export const createConnection = createAsyncThunk(
  "connection/createConnection",
  async (
    { connectionName, parentFolderId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const folderName = getState()?.folders?.selectedFolderName;
      if(folderName==='Trash'){
        const folderData=  getState()?.folders?.folders;
        const homeFolder = folderData.find(folder => folder.name === "Home");
        parentFolderId = homeFolder._id;
      }

      const requestData = {
        name: connectionName.trim(),
        folder_id: parentFolderId,
      };

      const response = await axiosInstance.post('/connections/', requestData);   
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in creating connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const renameConnection = createAsyncThunk(
  "connection/renameConnection",
  async ({ connectionId, newName }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/connections/rename/${connectionId}`,
        { name: newName }
      );
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Connection Renamed successfully",
      });
      return { connectionId, newName, data: response.data };
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in renaming connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteConnection = createAsyncThunk(
  "connection/deleteConnection",
  async (connectionIdArray, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.delete("/connections/", {
        data: { connection_ids: connectionIdArray },
      });
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Connection deleted successfully",
      });
      await dispatch(setIsSelectActionVisible(false));
      await dispatch(fetchFolders("null"));
      await dispatch(fetchStats())
      return { connectionIdArray, data: response.data };
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in deleting connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFilteredConnections = createAsyncThunk(
  "connection/fetchFilteredConnections",
  async ({ status, name, folder_id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/connections/filter-connections`,
        {
          params: {
            status,
            name,
            folder_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching filtered connections",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    } finally {
    }
  }
);

export const fetchPaginatedConnections = createAsyncThunk(
  "page/fetchPaginatedConnections",
  async ({ page, limit }, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();

      const { folders } = state.folders;
      const selectedFolderId = state.folders.selectedFolderId;
      const isInitialFetch = state.isInitialFetch;

      let folderId;

      // Check if it's the initial fetch and get the "Home" folder
      if (isInitialFetch) {
        const homeFolder = folders.find((folder) => folder.name === "Home");
        if (!homeFolder || !homeFolder._id) {
          throw new Error("Home folder not found or invalid.");
        }
        folderId = homeFolder._id;
      } else {
        // Use selected folder ID
        folderId = selectedFolderId;
        if (!folderId) {
          throw new Error("Selected folder ID is invalid.");
        }
      }

      // Make the API request with the correct folder ID
      const response = await axiosInstance.get(`/connections`, {
        params: {
          folder_id: folderId,
          page,
          limit,
        },
      });

      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message || error.message;

      if (error?.response?.data?.data?.msg) {
        errorMessage = error?.response?.data?.data?.msg;
      }

      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching connections",
        text: errorMessage,
      });

      return rejectWithValue(
        error.response ? error.response.data : errorMessage
      );
    }
  }
);


export const getUserAllConnections = createAsyncThunk(
  "connection/getUserAllConnections",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/connections/user-all-connections`
      );
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in getting all connections of user",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const moveConnection = createAsyncThunk(
  "connection/moveConnection",
  async (
    { connectionIds, fromFolderId, toFolderId },
    { dispatch, getState, rejectWithValue }
  ) => {
    const requestData = {
      connectionIds,
      fromFolderId,
      toFolderId,
    };
    try {
      const response = await axiosInstance.put(
        "/folders/move-connection",
        requestData
      );
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Connection moved successfully",
      });
      const folderID = getState()?.selectedFolder?.selectedFolderId;
      dispatch(fetchPaginatedConnections(folderID));

      dispatch(
        updateFolderConnectionCounts({
          fromFolderId,
          toFolderId,
          count: connectionIds.length,
        })
      );
      await dispatch(fetchFolders("null"));

      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in moving connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleConnection = createAsyncThunk(
  "connections/fetchSingleConnection",
  async (connectionId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `connections/single-connection/${connectionId}`
      );
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error fetching the connection",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaginatedFilteredConnections = createAsyncThunk(
  "connection/fetchPaginatedFilteredConnections",
  async (
    { status, name, folder_id, page, limit },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.get(
        `/connections?filter=true`,
        {
          params: {
            status,
            name,
            folder_id,
            page,
            limit,
          },
        }
      );
      // await dispatch(fetchPaginatedConnections(folder_id));
      return { ...response.data, page, limit };
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching connections",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnectionName: (state, action) => {
      state.connectionName = action.payload;
    },
    setConnectionId: (state, action) => {
      state.connectionId = action.payload;
    },
    setIsFilterApplied: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    setIsSelectActionVisible(state, action) {
      state.isSelectActionVisible = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setCheckedItems: (state, action) => {
      state.checkedItems = action.payload;
    },
    addCheckedItem: (state, action) => {
      state.checkedItems.push(action.payload);
    },
    removeCheckedItem: (state, action) => {
      state.checkedItems = state.checkedItems.filter(
        (id) => id !== action.payload
      );
    },
    clearCheckedItems: (state) => {
      state.checkedItems = [];
    },
    setIsRequestCountClicked:(state,action)=>{
      state.isRequestCountClicked=action.payload;
    },
    setIsEventCountClicked:(state,action)=>{
      state.isEventCountClicked=action.payload;
    },
    setRequestConnectionId:(state,action)=>{
      state.requestConnectionId=action.payload;
    },
    setEventConnectionId:(state,action)=>{
      state.eventConnectionId=action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createConnection.pending, (state) => {
        state.status = "loading";
        state.loading=true;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.connections.push(action.payload);
        state.loading=false;
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading=false;
      })
      .addCase(fetchPaginatedConnections.pending, (state) => {
        state.paginatedConnectionsStatus = "pending";
      })
      .addCase(fetchPaginatedConnections.fulfilled, (state, action) => {
        state.paginatedConnectionsStatus = "fulfilled";
        state.connections = action.payload.connections;
        state.totalItems = action.payload.total;
        state.isInitialFetch = false;
      })
      .addCase(fetchPaginatedConnections.rejected, (state, action) => {
        state.paginatedConnectionsStatus = "rejected";
        state.error = action.payload;
        state.isInitialFetch = false;
      })
      .addCase(renameConnection.fulfilled, (state, action) => {
        const { connectionId, newName } = action.payload;
        const existingConnection = state.connections.find(
          (conn) => conn.connection_id === connectionId
        );
        if (existingConnection) {
          existingConnection.name = newName;
        }
      })
      .addCase(renameConnection.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteConnection.pending, (state) => {
        state.paginatedConnectionsStatus = 'pending';
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        const { connectionIdArray } = action.payload;
        state.connections = state.connections.filter(conn => !connectionIdArray.includes(conn.connection_id));
        state.paginatedConnectionsStatus = 'fulfilled';
      })
      .addCase(deleteConnection.rejected, (state, action) => {
        state.error = action.payload;
        state.paginatedConnectionsStatus = 'rejected';
      })
      .addCase(getUserAllConnections.pending, (state) => {
        state.getAllConnectionStatus = "loading";
      })
      .addCase(getUserAllConnections.fulfilled, (state, action) => {
        state.getAllConnectionStatus = "succeeded";
        state.getAllConnectionStatus = action.payload;
        state.isSelectActionVisible = false;
      })
      .addCase(getUserAllConnections.rejected, (state, action) => {
        state.getAllConnectionStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchFilteredConnections.pending, (state) => {
        state.childLoading = true;
      })
      .addCase(fetchFilteredConnections.fulfilled, (state, action) => {
        state.childLoading = false;
        state.connections = action.payload.data;
        state.totalItems = action.payload.totalDocuments;
        state.isFilterApplied = true;
        state.isSelectActionVisible = false;
      })
      .addCase(fetchFilteredConnections.rejected, (state, action) => {
        state.childLoading = false;
        state.error = action.payload;
      })
      .addCase(moveConnection.pending, (state) => {
        state.status = "loading";
        state.paginatedConnectionsStatus = "pending";
      })
      .addCase(moveConnection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.moveConnections = action.payload.connections;
        state.isSelectActionVisible = false;
      })
      .addCase(moveConnection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSingleConnection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleConnection.fulfilled, (state, action) => {
        state.loading = false;
        state.connectionUpdateData = action.payload;
      })
      .addCase(fetchSingleConnection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPaginatedFilteredConnections.pending, (state) => {
        state.paginatedConnectionsStatus = "pending";
        state.parentLoading = true;
        state.childLoading = true;
      })
      .addCase(fetchPaginatedFilteredConnections.fulfilled, (state, action) => {
        state.paginatedConnectionsStatus = "fulfilled";
        state.parentLoading = false;
        state.childLoading = false;
        state.connections = action.payload.connections;
        state.totalItems = action.payload.totalDocuments;
        state.currentPage = action.payload.page;
        state.itemsPerPage = action.payload.limit;
        state.isInitialFetch = false;
        state.isFilterApplied = true;
        state.isSelectActionVisible = false;
      })
      .addCase(fetchPaginatedFilteredConnections.rejected, (state, action) => {
        state.paginatedConnectionsStatus = "rejected";
        state.parentLoading = false;
        state.childLoading = false;
        state.error = action.payload;
        state.isInitialFetch = false;
      });
  },
});

export const { setIsFilterApplied, setIsSelectActionVisible } =
  connectionSlice.actions;

export const selectConnectionNames = (state) => {
  const { connections } = state.connection || {};
  const { userConnections } = connections || [];

  if (!userConnections) {
    return [];
  }

  return userConnections.map((conn) => conn.name);
};

export const selectConnections = (state) => {
  const { connections } = state.connection || [];
  return connections || [];
};

export const {
  setCheckedItems,
  addCheckedItem,
  removeCheckedItem,
  clearCheckedItems,
} = connectionSlice.actions;

export const {
  setConnectionName,
  setConnectionId,
  setCurrentPage,
  setItemsPerPage,
  setTotalItems,
  setIsRequestCountClicked,
  setIsEventCountClicked,
  setRequestConnectionId,
  setEventConnectionId
} = connectionSlice.actions;
export default connectionSlice.reducer;

import React from 'react';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import { Logo } from './logo';  // Ensure this import path is correct

function AnimateLogo1({ logo, sx, ...other }) {
  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      {...other}
    >
      <motion.div
        animate={{ 
          scale: [1, 0.9, 0.9, 1, 1], 
          opacity: [1, 0.48, 0.48, 1, 1] 
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
        style={{ display: 'inline-flex' }}
      >
        {logo ?? <Logo disableLink width={64} height={64} />}
      </motion.div>

      <motion.div
        animate={{
          scale: [1.6, 1, 1, 1.6, 1.6],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ duration: 3.2, repeat: Infinity, ease: 'linear' }}
        style={{
          position: 'absolute',
          width: 'calc(100% - 20px)',
          height: 'calc(100% - 20px)',
          border: '3px solid #5BE49B',
        }}
      />

      <motion.div
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ duration: 3.2, repeat: Infinity, ease: 'linear' }}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          border: '3px solid #5BE49B',
        }}
      />
    </Box>
  );
}

export default AnimateLogo1;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import Swal from "sweetalert2";

const initialState = {
  events: [],
  eventsID: [],
  loading: false,
  loadingEventsById: false,
  status: "idle",
  error: null,
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 10,
  isFilterApplied: false,
  currentFilter: null,
};

export const fetchEventById = createAsyncThunk(
  "event/fetchEventById",
  async (evtId, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/events/${evtId}`);
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching events by Id",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const filterEvents = createAsyncThunk(
  "events/filterEvents",
  async (filterCriteria, thunkAPI) => {
    const {...params } = filterCriteria;
    try {
      const response = await axiosInstance.get(
        `/events?filter=true`,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in filtering events",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const fetchPaginatedEvents = createAsyncThunk(
  "page/fetchPaginatedEvents",
  async ({ page, limit, filterCriteria }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/events`, {
        params: { page, limit, ...filterCriteria },
      });
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching paginated events",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setIsFilterAppliedForevents: (state, action) => {
      state.isFilterApplied = action.payload;
    },
    setCurrentFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventById.pending, (state) => {
        state.loadingEventsById = true;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        state.loadingEventsById = false;
        state.eventsID = action.payload;
        state.error = null;
      })
      .addCase(fetchEventById.rejected, (state, action) => {
        state.loadingEventsById = false;
        state.error = action.payload;
      })
      .addCase(filterEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filterEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.events = action.payload.data;
        state.totalItems = action.payload.totalDocuments;
        state.isFilterApplied = true;
        state.currentPage = action.payload.currentPage || 1;
        state.itemsPerPage = action.payload.limit || state.itemsPerPage;
      })
      .addCase(filterEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPaginatedEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaginatedEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.events = action.payload.data;
        state.totalItems = action.payload.total;
        state.startIndex = (state.currentPage - 1) * state.itemsPerPage + 1;
        state.endIndex = Math.min(state.startIndex + state.itemsPerPage - 1, state.totalItems);
      })
      .addCase(fetchPaginatedEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
  },
});


export const selectDataState = (state) => state?.events;

export const { 
  setCurrentPage, 
  setItemsPerPage, 
  setIsFilterAppliedForevents, 
  setCurrentFilter 
} = eventSlice.actions;


export default eventSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
export const fetchCsrfToken = createAsyncThunk(
  "csrf/fetchCsrfToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/csrf-token/", { withCredentials: true });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const csrfSlice = createSlice({
  name: "csrf",
  initialState: {
    token: null,
    status: "idle",
    error: null,
  },
  reducers: {
    clearCsrfToken: (state) => {
      state.token = null;
      state.status = "idle";
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCsrfToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCsrfToken.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload.csrfToken;
      })
      .addCase(fetchCsrfToken.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { clearCsrfToken } = csrfSlice.actions;
export default csrfSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../utils/axiosInstance";
import Swal from 'sweetalert2';
import { fetchPaginatedConnections } from './connectionSlice';

const initialState = {
  initialFetch:true,
  selectedFolderName: '',
  folders: [],
  selectedFolderId: '',
  status: 'idle',
  error: null,
  isSelectActionVisisble:false,
  openFolders: [],
  localActiveFolderId: null,
  latestQueryDate: null,
};

export const fetchFolders = createAsyncThunk(
  'selectedFolder/fetchFolders',
  async (latestQueryDate, { rejectWithValue }) => {
    try {
      // Fetch folders only if there are new updates
      const response = await axiosInstance.get(`/folders?latest_query_date=${latestQueryDate}`);
      return response.data; // Return full response object (not just `data.data`) to access `latestQueryDate`
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml: '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching folders",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);


export const createFolder = createAsyncThunk(
  'selectedFolder/createFolder',
  async ({ folderName, parentFolderId }, { dispatch, getState ,rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`/folders`, {
        name: folderName,
        parent_id: parentFolderId || null,
      });
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Folder has been created successfully",
      });
      dispatch(fetchFolders("null"));
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in creating folder",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const renameFolder = createAsyncThunk(
  'selectedFolder/renameFolder',
  async ({ folderID, newName }, {dispatch,getState,rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/folders/rename/${folderID}`, { name: newName });
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Folder Renamed successfully',
      });
      dispatch(fetchFolders("null"));
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in renaming folder",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFolder = createAsyncThunk(
  'selectedFolder/deleteFolder',
  async (folderID, { dispatch,getState,rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/folders/${folderID}`);
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: 'Success',
        text: 'Folder Deleted successfully',
      });

      dispatch(fetchFolders("null"));
      return folderID; 
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:'<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error deleting folder",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

const insertFolder = (folders, newFolder) => {
  return folders.map(folder => {
    if (folder._id === newFolder.parent_id) {
      return {
        ...folder,
        subfolders: [...(folder.subfolders || []), newFolder]
      };
    } else if (folder.subfolders) {
      return {
        ...folder,
        subfolders: insertFolder(folder.subfolders, newFolder)
      };
    }
    return folder;
  });
};


const FolderSlice = createSlice({
  name: 'selectedFolder',
  initialState,
  reducers: {
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload;
    },
    setSelectedFolderName: (state, action) => {
      state.selectedFolderName = action.payload;
    },
    updateFolderConnectionCounts: (state, action) => {
      const { fromFolderId, toFolderId, count } = action.payload;
      const fromFolder = state.folders.find(f => f._id === fromFolderId);
      const toFolder = state.folders.find(f => f._id === toFolderId);
      if (fromFolder) fromFolder.connection_count -= count;
      if (toFolder) toFolder.connection_count += count;
    },
    setLocalActiveFolderId: (state, action) => {
      state.localActiveFolderId = action.payload;
    },
    toggleOpenFolder: (state, action) => {
      const folderId = action.payload;
      if (state.openFolders.includes(folderId)) {
        state.openFolders = state.openFolders.filter(id => id !== folderId);
      } else {
        state.openFolders.push(folderId);
      }
    },
    setLocalActiveFolderId: (state, action) => {
      state.localActiveFolderId = action.payload;
  
      const openParentFolders = (folders, targetId) => {
        for (let folder of folders) {
          if (folder._id === targetId) {
            return true;
          }
          if (folder.subfolders && openParentFolders(folder.subfolders, targetId)) {
            if (!state.openFolders.includes(folder._id)) {
              state.openFolders.push(folder._id);
            }
            return true;
          }
        }
        return false;
      };

      openParentFolders(state.folders, action.payload);
    },
    toggleOpenFolder: (state, action) => {
      const folderId = action.payload;
      if (state.openFolders.includes(folderId)) {
        state.openFolders = state.openFolders.filter(id => id !== folderId);
      } else {
        state.openFolders.push(folderId);
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchFolders.pending, (state) => {
      if(state.initialFetch){
      state.status = 'loading';
      }else{
      state.folders = state.folders;
      }
    })
    .addCase(fetchFolders.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // Handle the case when there's no new data
      if (action.payload.status !== 'no_new_data') {
        state.folders = action.payload.data; // Only update folders if new data is present
      }
      // Update the latestQueryDate with the server's response
      state.latestQueryDate = action.payload.latestQueryDate;
      // Handle the first-time folder selection on initial fetch
      if (state.initialFetch) {
        const folderData = action.payload.data;
        const homeFolder = folderData.find(folder => folder.name === 'Home');
        state.selectedFolderId = homeFolder._id;
        state.localActiveFolderId = homeFolder._id;
        state.selectedFolderName = homeFolder.name;
        state.initialFetch = false;
      }
      
      // Dispatch fetchPaginatedConnections here
      return action.asyncDispatch(fetchPaginatedConnections({
        page: 1,
        limit: 10, // or whatever your default limit is
      }));
    })
    .addCase(fetchFolders.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload || action.error.message;
    })

      .addCase(createFolder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.folders = insertFolder(state.folders, action.payload);
      })
      .addCase(createFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(renameFolder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(renameFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { id, name } = action.payload;
        if (state.selectedFolderId === id) {
          state.selectedFolderName = name;
        }
        const folder = state.folders.find((folder) => folder._id === id);
        if (folder) {
          folder.name = name;
        }
      })
      .addCase(renameFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteFolder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const deletedFolderId = action.payload;
        state.folders = state.folders.filter((folder) => folder._id !== deletedFolderId);
        if (state.selectedFolderId === deletedFolderId) {
          state.selectedFolderId = '';
          state.selectedFolderName = '';
        }
      })
      .addCase(deleteFolder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setSelectedFolderId, 
  setSelectedFolderName, 
  updateFolderConnectionCounts,
  setLocalActiveFolderId,
  toggleOpenFolder,
  } = FolderSlice.actions;
export default FolderSlice.reducer;
export const getSelectedFolderId = (state) => state.selectedFolderId;

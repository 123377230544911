import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import Swal from "sweetalert2";

const initialState = {
  code: `(request, context) => {
    return request;
  }`,
  updatedCode: "",
  inputJson: JSON.stringify(
    {
      customerId: "12345",
      payload: {
        items: [
          { id: 1, name: "Item One", status: "active" },
          { id: 2, name: "Item Two", status: "inactive" },
          { id: 3, name: "Item Three", status: "active" },
        ],
      },
      headers: {
        "Content-Type": "application/json",
      },
      queryParams: {
        page: 1,
      },
    },
    null,
    2
  ),
  outputJson: "",
  consoleOutput: "",
  theme: "vs-light",
  activeTab: "Input",
  isEditMode: false,
  isLoading: false,
  jsonError: null,
  transformations: [],
  trsID: [],
  loading: false,
  loadingEventsById: false,
  status: "idle",
  error: null,
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 10,
  isFilterApplied: false,
  currentFilter: null,
  createTransformationStatus: {
    loading: false,
    success: false,
    error: null,
  },
  getUserAllTransformationStatus: {
    loading: false,
    success: false,
    error: null,
  },
  updateTransformationStatus: {
    loading: false,
    success: false,
    error: null,
  },
};

export const createTransformation = createAsyncThunk(
  "transform/createConnection",
  async ({ transformationName, transformationCode }, { rejectWithValue }) => {
    const transformationData = {
      name: transformationName.trim(),
      code: transformationCode,
    };

    try {
      const response = await axiosInstance.post(
        "/transformations",
        transformationData
      );
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Transformation saved succesfully!",
      });
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error saving transformation",
        text: errorMessage,
      });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateTransformation = createAsyncThunk(
  "transform/updateTransformation",
  async (
    { trs_id, transformationName, transformationCode },
    { rejectWithValue }
  ) => {
    const transformationData = {
      name: transformationName.trim(),
      code: transformationCode,
    };

    try {
      const response = await axiosInstance.put(
        `/transformations/${trs_id}`,
        transformationData
      );
      Swal.fire({
        iconHtml: '<i class="fas fa-check-circle text-success font-60 "></i>',
        title: "Success",
        text: "Transformation Updated succesfully!",
      });
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error updating transformation",
        text: errorMessage,
      });
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getUserAllTransformation = createAsyncThunk(
  "transform/getUserAllTransformation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/transformations/get-all`);
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in getting all connections of user",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTransformationById = createAsyncThunk(
  "transform/fetchTransformationById",
  async (trs_Id, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/transformations/${trs_Id}`);
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching events by Id",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaginatedTransformation = createAsyncThunk(
  "page/fetchPaginatedTransformation",
  async ({ page, limit, filterCriteria }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/transformations`, {
        params: { page, limit, ...filterCriteria },
      });
      return response.data;
    } catch (error) {
      let errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.data?.msg)
        errorMessage = error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching transformation",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

const TransformationSlice = createSlice({
  name: "Transformation",
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setInputJson: (state, action) => {
      state.inputJson = action.payload;
      try {
        JSON.parse(action.payload);
        state.jsonError = null;
      } catch (error) {
        state.jsonError = "Invalid JSON: " + error.message;
      }
    },
    setOutputJson: (state, action) => {
      state.outputJson = action.payload;
    },
    setConsoleOutput: (state, action) => {
      state.consoleOutput = action.payload;
    },
    resetTransformation: (state) => {
      state.code = initialState.code;
      state.updatedCode = initialState.updatedCode;
      state.inputJson = initialState.inputJson;
      state.outputJson = "";
      state.consoleOutput = "";
      state.activeTab = "Input";
    },
    switchTheme: (state) => {
      state.theme = state.theme === "vs-dark" ? "vs-light" : "vs-dark";
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    clearConsole: (state) => {
      state.consoleOutput = "";
    },
    setUpdatedCode: (state, action) => {
      state.updatedCode = action.payload;
    },
    runTransformation: (state) => {
      if (state.jsonError) {
        Swal.fire({
          iconHtml:
            '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
          title: "Invalid JSON",
          text: "Please fix the JSON errors before running the transformation.",
        });
        return;
      }
      state.isLoading = true;
      let logs = [];
      const originalConsoleLog = console.log;
      const originalConsoleError = console.error;

      console.log = (message) => {
        logs.push(message);
        originalConsoleLog(message);
      };

      console.error = (message) => {
        logs.push(`Error: ${message}`);
        originalConsoleError(message);
      };

      try {
        const transformFunction = new Function(
          "request, context",
          `return (${state.updatedCode || state.code})(request, context);`
        );
        const inputData = JSON.parse(state.inputJson);
        const transformedData = transformFunction(inputData, {});
        state.outputJson = JSON.stringify(transformedData, null, 2);
        if (state.outputJson === undefined) {
          state.outputJson = "{}";
        }
        state.activeTab = "Output";
      } catch (error) {
        console.error(error.message);
        state.outputJson = JSON.stringify({ error: error.message }, null, 2);
      } finally {
        console.log = originalConsoleLog;
        console.error = originalConsoleError;
        state.consoleOutput = logs.join("\n");
        state.isLoading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaginatedTransformation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaginatedTransformation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transformations = action.payload.transformations;
        state.totalItems = action.payload.totalTransformations;
        state.startIndex = (state.currentPage - 1) * state.itemsPerPage + 1;
        state.endIndex = Math.min(
          state.startIndex + state.itemsPerPage - 1,
          state.totalItems
        );
      })
      .addCase(fetchPaginatedTransformation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchTransformationById.pending, (state) => {
        state.loadingEventsById = true;
      })
      .addCase(fetchTransformationById.fulfilled, (state, action) => {
        state.loadingEventsById = false;
        state.trsID = action.payload;
        state.error = null;
        state.updatedCode = action.payload;
      })
      .addCase(fetchTransformationById.rejected, (state, action) => {
        state.loadingEventsById = false;
        state.error = action.payload;
      })
      .addCase(createTransformation.pending, (state) => {
        state.createTransformationStatus = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(createTransformation.fulfilled, (state, action) => {
        state.createTransformationStatus = {
          loading: false,
          success: true,
          error: null,
        };
        state.transformations.push(action.payload);
      })
      .addCase(createTransformation.rejected, (state, action) => {
        state.createTransformationStatus = {
          loading: false,
          success: false,
          error: action.payload,
        };
      })
      .addCase(getUserAllTransformation.pending, (state) => {
        state.getUserAllTransformationStatus = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(getUserAllTransformation.fulfilled, (state, action) => {
        state.getUserAllTransformationStatus = {
          loading: false,
          success: true,
          error: null,
        };
        state.transformations = action.payload;
      })
      .addCase(getUserAllTransformation.rejected, (state, action) => {
        state.getUserAllTransformationStatus = {
          loading: false,
          success: false,
          error: action.payload,
        };
      })
      .addCase(updateTransformation.pending, (state) => {
        state.updateTransformationStatus = {
          loading: true,
          success: false,
          error: null,
        };
      })
      .addCase(updateTransformation.fulfilled, (state, action) => {
        state.updateTransformationStatus = {
          loading: false,
          success: true,
          error: null,
        };
        const index = state.transformations.findIndex(
          (tr) => tr._id === action.payload._id
        );
        if (index !== -1) {
          state.transformations[index] = action.payload; // Update the specific transformation
        }
      })
      .addCase(updateTransformation.rejected, (state, action) => {
        state.updateTransformationStatus = {
          loading: false,
          success: false,
          error: action.payload,
        };
      });
  },
});

export const selectDataState = (state) => state?.transformations;

export const {
  setCode,
  setInputJson,
  setOutputJson,
  setConsoleOutput,
  resetTransformation,
  switchTheme,
  setActiveTab,
  setIsEditMode,
  runTransformation,
  setCurrentPage,
  setItemsPerPage,
  clearConsole,
  setUpdatedCode,
} = TransformationSlice.actions;

export default TransformationSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../utils/axiosInstance";
import Swal from 'sweetalert2';

export const fetchTotalDetails = createAsyncThunk(
  'CreditBlockCard/fetchTotalDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/connection/total-details`, { withCredentials: true });
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching total details",
        text: errorMessage,
      });
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  totalConnections: '',
  totalRequests: '',
  totalEvents: '',
  freeTasksConsumed: '',
  status: 'idle',
  error: null,
};

const CreditBlockCardSlice = createSlice({
  name: 'CreditBlockCard',
  initialState,
  reducers: {
    setTotalConnections: (state, action) => {
      state.totalConnections = action.payload;
    },
    setTotalRequests: (state, action) => {
      state.totalRequests = action.payload;
    },
    setTotalEvents: (state, action) => {
      state.totalEvents = action.payload;
    },
    setTotalFreeTasksConsumed: (state, action) => {
      state.freeTasksConsumed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.totalConnections = action.payload.totalConnections;
        state.totalRequests = action.payload.totalRequest;
        state.totalEvents = action.payload.totalEvents;
        state.freeTasksConsumed = action.payload.freeTasksConsumed;
      })
      .addCase(fetchTotalDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error('Error updating total details:', action.payload);
      });
  },
});

export const {
  setTotalConnections,
  setTotalEvents,
  setTotalFreeTasksConsumed,
  setTotalRequests,
} = CreditBlockCardSlice.actions;

export default CreditBlockCardSlice.reducer;

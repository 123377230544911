import { configureStore, combineReducers} from '@reduxjs/toolkit';
import connectionReducer from './slices/connectionSlice'; 
import webhookurlReducer from './slices/wehookUrlSlice';
import creditBlockCardReducer from './slices/creditBlockCardSlice';
import folderSlice from './slices/folderSlice';
import requestReducer from './slices/requestSlice';
import eventsReducer from './slices/eventSlice'
import userReducer from "../redux/slices/authSlice"
import csrfReducer from './slices/csrfSlice';
import actionReducer from './slices/actionSlice';
import transformationReducer from './slices/transformationSlice';
import connectionFormReducer from './slices/connectionFormSlice';
import statsReducer from './slices/statsSlice';
import asyncDispatchMiddleware from '../utils/asyncDispatchMiddleware';

const appReducer = combineReducers({
  folders: folderSlice,
  connection: connectionReducer,
  url: webhookurlReducer,
  CreditBlockCard: creditBlockCardReducer,
  selectedFolder: folderSlice,
  request: requestReducer,
  events: eventsReducer,
  user: userReducer,
  csrf: csrfReducer,
  action: actionReducer,
  transformation: transformationReducer,
  connectionForm: connectionFormReducer,
  stats:statsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'user/resetState') {
    // Reset all slices to their initial state
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['user/verifySession/fulfilled'],
      ignoredPaths: ['payload.headers', 'payload.config', 'payload.request','user.user.headers',`user.user.config.transformRequest.0`,
        `user.user.config.transformResponse.0`,
         `user.user.config.env.FormData`,
         `user.user.config.env.Blob`,
         `user.user.config.validateStatus`,
         `user.user.config.headers`,
         `user.user.request`
      ],
    },
  }).concat(asyncDispatchMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resetSignupSuccess } from '../redux/slices/authSlice.js';


const LoginPage = () => {
  const [input, setInput] = useState({
    email: '',
    password: ''
  });

  const { signupSuccess } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (signupSuccess) {
      dispatch(resetSignupSuccess());
    } 
  }, [signupSuccess, dispatch]);

  const navigate = useNavigate();
  const { user, status, error } = useSelector((state) => state?.user);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((prevInput) => ({ ...prevInput, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(input));
  };

  useEffect(() => {
    if (status === 'succeeded' && user) {
      navigate('/app'); 
    }
  }, [status, user, navigate]);

  return (
    <div className="login-container w-100">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={input.email}
              onChange={handleInput}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={input.password}
              onChange={handleInput}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        <div className="header-description-container">
          <p className="login-text m-t-10 m-b-5">Don't have a Pabbly Account yet?</p>
          <button
            className="btn btn-success create-account-btn"
            onClick={() => navigate('/signUp')}
          >
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../redux/slices/authSlice.js';
import "../../src/assets/login.css";

const SignUpPage = () => {
  const [input, setInput] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const { status, error } = user;

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((prevInput) => ({ ...prevInput, [name]: value }));
  };

  const handleSubmit = (e) => {
    dispatch(signupUser(input))
  };

  return (
    <div className="login-container w-100">
      <div className="login-form">
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={input.first_name}
              onChange={handleInput}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={input.last_name}
              onChange={handleInput}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={input.email}
              onChange={handleInput}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={input.password}
              onChange={handleInput}
              required
            />
          </div>
          <button type="submit" className="login-button" disabled={status === 'loading'}>
            Sign up
          </button>

          <div className="d-flex justify-content-end m-t-10">
            <p className="login-text m-t-10 m-b-5 m-r-10">Already have an account?</p>
            <button
              className="btn btn-success create-account-btn"
              onClick={() => navigate('/app')}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;

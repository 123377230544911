import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    url: '',
};

const wehookUrlSlice = createSlice({
  name: 'url',
  initialState,
  reducers: {
    setUrl: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const { setUrl } = wehookUrlSlice.actions;
export default wehookUrlSlice.reducer;
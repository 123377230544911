import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../utils/axiosInstance";

const STATS_STORAGE_KEY = 'appStats';

// Helper function to get stats from local storage
const getStatsFromStorage = () => {
  const storedStats = localStorage.getItem(STATS_STORAGE_KEY);
  return storedStats ? JSON.parse(storedStats) : null;
};

// Helper function to set stats in local storage
const setStatsInStorage = (stats) => {
  localStorage.setItem(STATS_STORAGE_KEY, JSON.stringify(stats));
};

// Async thunk to fetch stats from the server
export const fetchStats = createAsyncThunk('stats/fetchStats', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/stats');
    setStatsInStorage(response.data.data);  // Store the fetched stats in local storage
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    stats: getStatsFromStorage() || {
      totalConnections: 0,
      totalRequests: 0,
      totalEvents: 0,
    },
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stats = action.payload.data;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : 'Failed to fetch stats';
      });
  },
});

export default statsSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import Swal from "sweetalert2";

const initialState = {
  requests: [],
  requestsID: [],
  loading: false,
  loadingRequestById: false,
  error: null,
  currentPage: 1,
  totalItems: 0,
  itemsPerPage: 10,
  isFilterApplied: false,
};


export const fetchRequestsById = createAsyncThunk(
  "event/fetchRequestsById",
  async (ReqId, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/requests/${ReqId}`);
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in fetching request by Id",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const filterRequest = createAsyncThunk(
  "request/filterRequest",
  async (filterCriteria, thunkAPI) => {
    const {...params } = filterCriteria;
    try {
      const response = await axiosInstance.get(
        `/requests?filter=true`,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error in filtering request",
        text: errorMessage,
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaginatedRequests = createAsyncThunk(
  "page/fetchPaginatedRequests",
  async ({ page, limit, filterCriteria }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/requests`, {
        params: { page, limit, ...filterCriteria },
      });
      return response.data;
    } catch (error) {
      let errorMessage=error?.response?.data?.message;
      if(error?.response?.data?.data?.msg)
        errorMessage=error?.response?.data?.data?.msg;
      Swal.fire({
        iconHtml:
          '<i class="fas fa-exclamation-circle text-danger font-60"></i>',
        title: "Error fetching paginated requests",
        text: errorMessage,
      });
      return rejectWithValue(error.response.data);
    }
  }
);


const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setIsFilterAppliedForRequests: (state, action) => {
      state.isFilterApplied = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestsById.pending, (state) => {
        state.loadingRequestById = true;
      })
      .addCase(fetchRequestsById.fulfilled, (state, action) => {
        state.loadingRequestById = false;
        state.requestsID = action.payload;
        state.error = null;
      })
      .addCase(fetchRequestsById.rejected, (state, action) => {
        state.loadingRequestById = false;
        state.error = action.payload;
      })
      .addCase(filterRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filterRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requests = action.payload.data;
        state.totalItems = action.payload.totalDocuments;
        state.isFilterApplied = true; 
      })
      .addCase(filterRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPaginatedRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaginatedRequests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requests = action.payload.data;
        state.totalItems = action.payload.total;
      })
      .addCase(fetchPaginatedRequests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
  },
});

export const { setIsFilterAppliedForRequests } = requestSlice.actions;

export const { setCurrentPage, setItemsPerPage } = requestSlice.actions;

export const selectDataState = (state) => state.requests;


export default requestSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showFilterComponent: false,
  showFilterComponentForUpdate: false,
  activeTab: "body",
  jsonErrorFilterBody: "",
  showCustomResponseComponent: false,
  showCustomResponseComponentForUpdate: false,
  jsonErrorCustomresponse: null,
  jsonErrorCustomresponseForUpdate: null,
  customResponseObject: {
    status: "inactive",
    content_type: "application/json",
    content: "",
  },
  customResponseObjectForUpdate: {
    status: "inactive",
    content_type: "application/json",
    content: "",
  },
  jsonErrorFilterBody: "",
  jsonErrorFilterBodyForUpdate: "",
  filterObject:{
    status: "inactive",
    body: null,
    headers: null,
    query: null,
    path: null,
  },
  filterObjectForUpdate: {
    status: "inactive",
    body: null,
    headers: null,
    query: null,
    path: null,
  },
  activeTabForUpdate: "body",
  isBodyValid: true,
  isBodyValidForUpdate: true,
  tabContent: {
    body: "",
    headers: "",
    query: "",
    path: "",
  },
  tabContentForUpdate: {
    body: null,
    headers: null,
    query: null,
    path: null,
  }
};

export const connectionFormSlice = createSlice({
  name: "connectionForm",
  initialState,
  reducers: {

    
    toggleCustomResponseState: (state) => {
      state.showCustomResponseComponent = !state.showCustomResponseComponent;
      state.customResponseObject.status=(state.customResponseObject.status === "active" )? "inactive" : "active";
    },
    setCustomResponseObject: (state, action) => {
      state.customResponseObject = {
        ...state.customResponseObject,
        ...action.payload,
      }
    },
    validateJsonContent: (state) => {
    
      if (state.customResponseObject.content_type === "application/json") {
        try {
          JSON.parse(state.customResponseObject.content);
          state.jsonErrorCustomresponse = "";
        } catch (err) {
          state.jsonErrorCustomresponse = `Invalid JSON: ${err.message}`;
        }
      } else {
        state.jsonErrorCustomresponse = "";
      }
    },
    resetFormState: () => initialState,

    validateFilterBody: (state) => {
        if(state.activeTab==='body'){
        try {
          JSON.parse(state.tabContent.body);
          state.jsonErrorFilterBody = "";
        } catch (err) {
          state.jsonErrorFilterBody = `Invalid JSON in Body: ${err.message}`;
        }
      }else
      if(state.activeTab==='headers'){
        try {
          JSON.parse(state.tabContent.headers);
          state.jsonErrorFilterBody = "";
        } catch (err) {
          state.jsonErrorFilterBody = `Invalid JSON in Headers: ${err.message}`;
         
        }
      }else
      if(state.activeTab==='query'){
        try {
          JSON.parse(state.tabContent.query);
          state.jsonErrorFilterBody = "";
        } catch (err) {
          state.jsonErrorFilterBody = `Invalid JSON in Query: ${err.message}`;
          
        }
      }else
      if(state.activeTab==='path'){
        try {
          JSON.parse(state.tabContent.path);
          state.jsonErrorFilterBody = "";
        } catch (err) {
          state.jsonErrorFilterBody = `Invalid JSON in Path: ${err.message}`;
          
        }
      }else
        // Clear error if all JSON objects are valid
        state.jsonErrorFilterBody = "";
   
    },
    
    toggleCustomResponseStateForUpdate: (state) => {
      state.showCustomResponseComponentForUpdate = !state.showCustomResponseComponentForUpdate;
      state.customResponseObjectForUpdate.status=(state.customResponseObjectForUpdate.status === "active" )? "inactive" : "active";
    },
    setCustomResponseObjectForUpdate: (state, action) => {
       state.customResponseObjectForUpdate = {...state.customResponseObjectForUpdate, ...action.payload,  }; 
       if (state.customResponseObjectForUpdate.content_type === "application/json") { 
        try {
          JSON.parse(state.customResponseObjectForUpdate.content);
          state.jsonErrorCustomresponseForUpdate = "";
        } catch (err) {
          state.jsonErrorCustomresponseForUpdate = `Invalid JSON: ${err.message}`;
        }
      } else {
        state.jsonErrorCustomresponseForUpdate = "";
      }
    },
    validateJsonContentForUpdate: (state) => {
      if (state.customResponseObjectForUpdate.content_type === "application/json") { 
        try {
          JSON.parse(state.customResponseObjectForUpdate.content);
          state.jsonErrorCustomresponseForUpdate = "";
        } catch (err) {
          state.jsonErrorCustomresponseForUpdate = `Invalid JSON: ${err.message}`;
        }
      } else {
        state.jsonErrorCustomresponseForUpdate = "";
      }
    },
    setActiveTabForUpdate: (state, action) => {
      state.activeTabForUpdate = action.payload;
    },
    setFilterObjectForUpdate: (state, action) => {
      
      state.filterObjectForUpdate = {
      //  ...state.filterObjectForUpdate,
        ...action.payload,
      }
    },
    toggleFilter: (state) => {
      state.showFilterComponent = !state.showFilterComponent;
      state.filterObject.status =
        state.filterObject.status === "active" ? "inactive" : "active";
    },
    toggleFilterForUpdate: (state) => {
      state.showFilterComponentForUpdate = !state.showFilterComponentForUpdate;
      state.filterObjectForUpdate.status =
        state.filterObjectForUpdate.status === "active" ? "inactive" : "active";
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;

    },
    updateTabContent: (state, action) => {
      const { tab, content } = action.payload;
      state.tabContent[tab] = content;
      state.filterObject[tab] = content;
    },
    updateTabContentForUpdate: (state, action) => {
      const { tab, content } = action.payload;
      
      // Ensure the tab is valid
      if (!['body', 'headers', 'query', 'path'].includes(tab)) {
        console.error(`Invalid tab: ${tab}`);
        return;
      }
    
      // Validate and sanitize the content
      let sanitizedContent = content;
      if (typeof content === 'object' && content !== null) {
        try {
          // Attempt to stringify and re-parse to ensure valid JSON
          sanitizedContent = JSON.parse(JSON.stringify(content));
        } catch (error) {
          console.error(`Invalid JSON for ${tab}:`, error);
          return;
        }
      } else if (tab !== 'path' && typeof content !== 'object') {
        console.error(`Invalid content type for ${tab}. Expected object, got ${typeof content}`);
        return;
      }
    
      // Update the state
      state.tabContentForUpdate = {
        ...state.tabContentForUpdate,
        [tab]: sanitizedContent
      };
    
      state.filterObjectForUpdate = {
        ...state.filterObjectForUpdate,
        [tab]: sanitizedContent
      };
    
      // Ensure 'status' remains in filterObjectForUpdate if it's not being updated
      if (!state.filterObjectForUpdate.hasOwnProperty('status')) {
        state.filterObjectForUpdate.status = state.filterObjectForUpdate.status || 'inactive';
      }
    
    },
    setTabContentForUpdate :(state,action)=>{
      const { status, body, headers, query, path } = action.payload;
      state.showFilterComponentForUpdate = status === 'active';
      state.tabContentForUpdate = { body, headers, query, path };
      state.filterObjectForUpdate = { status, body, headers, query, path };
      state.showFilterComponentForUpdate=true;
    },
    setFilterObject: (state, action) => {
      state.filterObject = {
        ...state.filterObject,
        ...action.payload,
      };

    },

    validateFilterBodyForUpdate: (state) => {
        if(state.activeTabForUpdate==='body'){
        try {
          JSON.parse(state.tabContentForUpdate.body);
          state.jsonErrorFilterBodyForUpdate = "";
        } catch (err) {
          state.jsonErrorFilterBodyForUpdate = `Invalid JSON in Body: ${err.message}`;
        }
      }else
      if(state.activeTabForUpdate==='headers'){
        try {
          JSON.parse(state.tabContentForUpdate.headers);
          state.jsonErrorFilterBodyForUpdate = "";
        } catch (err) {
          state.jsonErrorFilterBodyForUpdate = `Invalid JSON in Headers: ${err.message}`;
        }
      }else
      if(state.activeTabForUpdate==='query'){
        try {
          JSON.parse(state.tabContentForUpdate.query);
          state.jsonErrorFilterBodyForUpdate = "";
        } catch (err) {
          state.jsonErrorFilterBodyForUpdate = `Invalid JSON in Query: ${err.message}`;
          
        }
      }else
      if(state.activeTabForUpdate==='path'){
        try {
          JSON.parse(state.tabContentForUpdate.path);
          state.jsonErrorFilterBodyForUpdate= "";
        } catch (err) {
          state.jsonErrorFilterBodyForUpdate = `Invalid JSON in Path: ${err.message}`;
          
        }
      }else
        // Clear error if all JSON objects are valid
        state.jsonErrorFilterBodyForUpdate = "";

    },

    updateAndValidateJsonContent: (state, action) => {
      state.customResponseObjectForUpdate.content = action.payload;
      if (state.customResponseObjectForUpdate.content_type === "application/json") {
        try {
          JSON.parse(state.customResponseObjectForUpdate.content);
          state.jsonErrorCustomresponseForUpdate = "";
        } catch (err) {
          state.jsonErrorCustomresponseForUpdate = `Invalid JSON: ${err.message}`;
        }
      } else {
        state.jsonErrorCustomresponseForUpdate = "";
      }
    },
    setContentType: (state, action) => {
      state.customResponseObjectForUpdate.content_type = action.payload;
    },
    
    // }
    setShowCustomResponseComponentForUpdate: (state, action) => {
      state.showCustomResponseComponentForUpdate = action.payload;
      state.customResponseObjectForUpdate.status = action.payload ? "active" : "inactive";
    },
    setShowFilterComponentForUpdate :(state,action)=>{
      state.showFilterComponent=action.payload;
    }
  }
});

export const {
  toggleCustomResponseState,
  setCustomResponseObject,
  validateJsonContent,
  resetFormState,
  toggleFilter,
  setActiveTab,
  updateTabContent,
  validateFilterBody,
  toggleCustomResponseStateForUpdate,
  setCustomResponseObjectForUpdate,
  validateJsonContentForUpdate,
  toggleFilterForUpdate,
  setActiveTabForUpdate,
  updateTabContentForUpdate,
  validateFilterBodyForUpdate,
  setFilterObjectForUpdate,
  setFilterObject,
  setShowCustomResponseComponentForUpdate,
  setShowFilterComponentForUpdate,
  updateAndValidateJsonContent,
  setContentType,
  initializeCustomResponse,
  setTabContentForUpdate
} = connectionFormSlice.actions;

export default connectionFormSlice.reducer;

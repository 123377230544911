import store from "../redux/store";
import axios from "axios";

const baseURL = process.env.NODE_ENV  === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PRODUCTION
  : process.env.REACT_APP_API_BASE_URL_DEVELOPMENT

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});


axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const csrfToken = state?.csrf?.token;

    if (csrfToken && ["post", "put", "patch", "delete"].includes(config.method)) {
      config.headers["X-CSRF-Token"] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

import store from './redux/store.js';
import React, { lazy, Suspense, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { verifySession } from './redux/slices/authSlice.js';
import { fetchCsrfToken } from './redux/slices/csrfSlice.js';
import LoginPage from "./pages/LoginPage.js"
import SignUpPage from './pages/SignUpPage.js';
import { resetFormState } from './redux/slices/connectionFormSlice.js';
import Loader from './pages/components/subComponents/loader.js';
import './index.css';
const Footer = lazy(() => import('./pages/components/Footer.js'));
const Navbar = lazy(() => import('./pages/components/Navbar.js'));

const Sidebar = lazy(() => import('./pages/components/Sidebar.js'));
const ConnectionsPage = lazy(() => import('./pages/ConnectionsPage.js'));
const IssuesPage = lazy(() => import('./pages/IssuesPage'));
const TransformationsPage = lazy(() => import('./pages/TransformationsPage'));
const TransformationHistoryPage = lazy(() => import('./pages/components/TransformationHistoryPage.js'));
const RequestsPage = lazy(() => import('./pages/RequestsPage'));
const EventsPage = lazy(() => import('./pages/EventsPage'));
const CreateConnectionsPage = lazy(() => import('./pages/CreateConnectionsPage.js'));
const UpdateConnectionsPage = lazy(() => import('./pages/components/UpdateConnectionsPage.js'));

const AppContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { user, status } = useSelector((state) => state?.user);
  const isLoggedIn = user && status === 'succeeded';
  const isInitialMount = useRef(true);

  const handleNavigation = useCallback((currentPath, savedPath) => {  
    if (currentPath === '/app' && savedPath && savedPath !== '/app') {
      navigate(savedPath, { replace: true });
    } else if (savedPath === '/app/create-connection' || savedPath === '/app/update-connection') {
      localStorage.removeItem('lastPath');
      navigate('/app', { replace: true });
    } else {
      localStorage.setItem('lastPath', currentPath);
    }
  }, [navigate]);

  useEffect(() => {    
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (isLoggedIn) {
      const currentPath = location.pathname;
      const savedPath = localStorage.getItem('lastPath');
      handleNavigation(currentPath, savedPath);
    }

    dispatch(resetFormState());
  }, [isLoggedIn, location.pathname, handleNavigation, dispatch]);

  useEffect(() => {
    dispatch(fetchCsrfToken());
    dispatch(verifySession());
  }, [dispatch]);


  if (status === 'loading') {
    return (
      <Loader/>
    );
  }

  return (
      <Suspense fallback={
        <Loader/>
      }>
        {isLoggedIn && <Navbar/>}
        <div className="container-fluid min-vh-100">
          <div className="row">
            {isLoggedIn && <Sidebar />}
            <Routes>
              <Route path="/app" element={isLoggedIn ? <ConnectionsPage /> : <LoginPage />} />
              <Route path="/signUp" element={<SignUpPage />} />
              {isLoggedIn ? (
                <>
                  <Route path="/app/issues" element={<IssuesPage />} />
                  <Route path="/app/transformations" element={<TransformationHistoryPage />} />
                  <Route path="/app/requests" element={<RequestsPage />} />
                  <Route path="/app/requests/:connectionId" element={<RequestsPage />} />
                  <Route path="/app/create-connection" element={<CreateConnectionsPage />} />
                  <Route path="/app/update-connection" element={<UpdateConnectionsPage />} />
                  <Route path="/app/events" element={<EventsPage />} />
                  <Route path="/app/events/:connectionId" element={<EventsPage />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/app" />} />
              )}
            </Routes>
          </div>
        </div>
        <Footer/>
      </Suspense>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;